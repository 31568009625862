<template>
  <div class="container">
    <van-nav-bar
        left-text="入职指引"
        left-arrow fixed placeholder
        @click-left="$router.back()"
        v-if="isShow"
    />
    <div class="main">
      <h1 class="title">入职流程</h1>
      <div class="linear">
        <div class="item">
          <div class="img">
            <img src="../../../static/img/11.png" alt="">
          </div>
          <p>办理入职</p>
        </div>
        <img src="../../../static/img/arrow.png" class="arrow" alt="">
        <div class="item">
          <div class="img">
            <img src="../../../static/img/12.png" alt="">
          </div>
          <p>商学院账号</p>
        </div>
        <img src="../../../static/img/arrow.png" class="arrow" alt="">
        <div class="item">
          <div class="img">
            <img src="../../../static/img/13.png" alt="">
          </div>
          <p>参观公司</p>
        </div>
        <img src="../../../static/img/arrow.png" class="arrow" alt="">
        <div class="item">
          <div class="img">
            <img src="../../../static/img/14.png" alt="">
          </div>
          <p>正式入职</p>
        </div>
      </div>
      <div class="info_item one">
        <div class="title">
          <img src="../../../static/img/15.png" alt="">
          <p>携带资料</p>
        </div>
        <div class="num">
          <p><span>①</span>身份证</p>
          <p><span>②</span>民生银行卡</p>
          <p><span>③</span>毕业证或者学信网电子学籍证明（已毕业）</p>
          <p><span>④</span>乙肝检查报告</p>
          <p><span>⑤</span>户口本本页及家庭索引页照片</p>
          <p><span>⑥</span>如若已婚，提供结婚证照片</p>
          <p><span>⑦</span>离职证明</p>
        </div>
      </div>
      <div class="info_item two">
        <div class="title">
          <img src="../../../static/img/16.png" alt="">
          <p>上班时间</p>
        </div>
        <p class="txt1">弹性八小时工作制，早上8:00-9:00上班打卡时间，中午12:00-13:00午餐时间，下午5:00-6:00下班打卡时间，来得早下班早哦~</p>
        <p class="txt2">中午午餐可到公司3楼餐厅，有各种美食可以选择~</p>
        <div class="tip">温馨提示：入职当天我们可以自带一个水杯！</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "enter",
  data(){
    return {
      isShow : true
    }
  },
  created() {
    let i = window.history.length;
    if(i == 1){
      this.isShow = false;
    }
  }
}
</script>

<style scoped lang="scss">
.main{
  width: 345px;
  margin: 0 auto;
  h1{
    font-family: PingFang-SC-Medium;
    font-size: 17px;
    font-weight: 500;
    font-stretch: normal;
    letter-spacing: 0px;
    color: #666666;
    text-align: center;
    margin-top: 15px;
    margin-bottom: 17px;
  }
  .linear{
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px dashed #d4d4d4;
    padding-bottom: 25px;
    .item{
      width: 25%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .img{
        width: 68px;
        height: 68px;
        box-sizing: border-box;
        background-color: #02dbca;
        box-shadow: 3px 3px 5px 0px
        rgba(190, 191, 191, 0.23);
        border: solid 2px #ffffff;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        img{
          width: auto;
          height: auto;
          max-width: 100%;
          max-height: 100%;
        }
      }
      p{
        font-family: PingFang-SC-Medium;
        font-size: 13px;
        font-weight: normal;
        font-stretch: normal;
        letter-spacing: 0px;
        color: #333333;
        margin-top: 8px;
        margin-bottom: 0;
      }
    }
    .arrow{
      width: 4px;
      height: 7px;
      flex-shrink: 0;
      margin-top: -15px;
    }
  }
  .info_item{
    .title{
      width: 130px;
      height: 37px;
      background-color: #02dbca;
      box-shadow: 0px 0px 7px 0px
      rgba(0, 0, 0, 0.11);
      border-radius: 19px;
      display: flex;
      align-items: center;
      margin-top: 5px;
      img{
        width: 30px;
        height: 30px;
        flex-shrink: 0;
        margin-left: 5px;
      }
      p{
        font-family: PingFang-SC-Medium;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        letter-spacing: 0px;
        color: #ffffff;
        margin-left: 7px;
      }
    }
  }
  .one{
    padding-top: 20px;
    .num{
      margin: 15px 0 20px;
      p{
        font-family: PingFang-SC-Medium;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        line-height: 28px;
        letter-spacing: 1px;
        color: #666666;
        display: flex;
        margin: 0;
        span{
          margin-top: -1px;
          margin-right: 2px;
        }
      }
    }
  }
  .two{
    padding-bottom: 30px;
    .title{
      background-color: #3399ff;
    }
    p{
      font-family: PingFang-SC-Medium;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      line-height: 23px;
      text-align: justify;
      letter-spacing: 0px;
      color: #333333;
      margin: 0;
    }
    .txt1{
      margin-top: 20px;
      padding-bottom: 13px;
      border-bottom: 1px dashed #d4d4d4;
    }
    .txt2{
      padding-top: 13px;
    }
    .tip{
      width: 312px;
      height: 38px;
      background-color: #fedada;
      border-radius: 19px;
      margin: 25px auto 0;
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: PingFang-SC-Medium;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      letter-spacing: 0px;
      color: #ff3333;
      span{
        font-family: PingFang-SC-Bold;
      }
    }
  }
}
::v-deep .van-nav-bar__arrow{
  font-size: 19px;
  color: #666666;
}
::v-deep .van-nav-bar__text{
  font-family: PingFang-SC-Medium;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  letter-spacing: 0px;
  color: #333333;
}
</style>
